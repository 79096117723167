import {ActivityIndicator, Alert, Text} from "react-native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../App.tsx";
import React, {useEffect} from "react";
import {AppContext} from "../Providers/AppContext.tsx";

type JumpScreenProps = NativeStackScreenProps<RootStackParamList, 'Jump'>;

export function JumpScreen({route, navigation}: JumpScreenProps) {

    const appContext = React.useContext(AppContext);

    useEffect(() => {
        const {isbn, token} = route.params!;

        if(!isbn || !token) {
            navigation.navigate('Home');
        }

        appContext.loginWithToken(token).then((result) => {
            if((result as any).status === 200) {
                appContext.syncData()
                    .then(() => {
                        (navigation as any).navigate('BookDetail', {'bookId': isbn});
                    })
            } else {
                navigation.navigate('Auth');
            }
        });
    }, []);

    return (
        <>
            {appContext.dataInSync &&
                <div style={{textAlign: 'center'}}>
                    <ActivityIndicator style={{marginTop: 48}} size={'large'} color={'#cc0000'} />
                    <h3 style={{fontFamily: 'roboto'}}>Attendi il caricamento della lista libri...</h3>
                </div>
            }
        </>
    )
}

export default JumpScreen;
