import { LoginResult } from '../Models/login-result';
import apiClient from './api-client';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Constants} from "../Models/constants";

export class AuthService {
  public login(username: string, password: string) {
    return apiClient.post<LoginResult>('/identity/login', { 'username': username, 'password': password })
  }

  public loginWithToken(token: string) {
    return apiClient.post<LoginResult>('/identity/login-with-token', { 'token': token })
  }

  public async logout(): Promise<boolean> {
    await AsyncStorage.multiRemove([Constants.DATA_IS_LOGGED_IN, Constants.DATA_BOOKS_LIST]);
    return Promise.resolve(true);
  }
}
